<template>
  <PrintWrapper>
    <div class="print">
      <h1>加工指令单</h1>

      <div class="box" v-for="(item, i) in list" :key="i">
        <div class="flex justify_start">
          <div class="img">
            <img :src="item.productImage.split(',')[0] || emptyImg" alt="" />
          </div>

          <div class="container">
            <ul>
              <li>
                <em>加工单号</em>
                <span>{{ item.processOrderCode }}</span>
              </li>
              <li>
                <em>PI号</em>
                <span>{{ item.salesOrderCode }}</span>
              </li>
              <li>
                <em>产品编码</em>
                <span>{{ item.skuId }}</span>
              </li>
              <li>
                <em>产品颜色</em>
                <span>{{ item.productSpecs }}</span>
              </li>
              <li>
                <em>加工数量</em>
                <span>{{ item.processNum + item.sampleNum }}</span>
              </li>
              <li>
                <em>领料数量</em>
                <span>{{ item.materialNum }}</span>
              </li>
              <li>
                <em>印刷方式</em>
                <span>{{ item.printWay }}</span>
              </li>
              <li>
                <em>印刷颜色</em>
                <span>{{ item.printColor }}</span>
              </li>
              <li>
                <em>印刷尺寸</em>
                <span>{{ item.printSize }}</span>
              </li>
              <li>
                <em>印刷描述</em>
                <span>{{ item.printDesc }}</span>
              </li>
              <li>
                <em>包装要求</em>
                <span>{{ item.packaging }}</span>
              </li>
              <li>
                <em>是否急单</em>
                <span>{{ item.urgent === 0 ? '否' : '是' }}</span>
              </li>
              <li>
                <em>运输类型</em>
                <span>{{ item.shippingMethodType | shippingMethodName }}</span>
              </li>
              <li>
                <em>下单时间</em>
                <span>{{ item.createTime }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </PrintWrapper>
</template>

<script>
  import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'
  import PrintWrapper from './printWrapper'
  export default {
    data() {
      return {
        list: [],
        emptyImg: require('@/assets/empty_images/picture.png'),
      }
    },
    components: {
      PrintWrapper,
    },
    filters: {
      shippingMethodName(type) {
        const map = {
          1: '空运',
          2: '海运',
          3: '快递',
        }
        return map[type]
      }
    },
    created() {
      const ids = this.$route.query.ids
      const type = this.$route.query.type
      this.init(ids, type)
    },
    methods: {
      /**
       * 初始化
       * @param {ids} ids
       * @param {type} 1：订单维度 2：产品维度
       */
      async init(ids, type) {
        let res
        if (type == 1) {
          res = await OutProcessing.getPrintDetail(ids)
        } else {
          res = await OutProcessing.getProductPrintDetail(ids)
        }
        const data = res.data

        if (res && res.code === '000000') {
          if (type == 1) {
            let _arr = []

            for (let i = 0; i < data.length; i++) {
              for (let n = 0; n < data[i]?.processProductVOList.length; n++) {
                const _obj = {
                  ...data[i].processProductVOList[n],
                  processOrderCode: data[i].processOrderCode,
                  salesOrderCode: data[i].salesOrderCode,
                  createTime: data[i].createTime
                }
                _arr.push(_obj)
              }
            }
            this.list = _arr
          } else {
            this.list = res.data
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .print {
    background: white;
    min-height: 100vh;
    width: 100%;
    margin-top: -20px;
    padding-top: 20px;

    h1 {
      font-size: 24px;
      text-align: center;
      line-height: 50px;
    }

    .box {
      width: 720px;
      margin: 0 auto 20px;
      page-break-after: auto;
      page-break-inside: avoid;
      -webkit-print-color-adjust: exact;
      font-size: 13px;

      .img {
        width: 330px;
        height: 330px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .container {
        width: 365px;
        border: 1px solid #afafaf;
        border-radius: 2px;
        margin-left: 12px;

        ul {
          margin-top: -1px;

          li {
            list-style: none;
            line-height: 29px;
            display: flex;
            border-top: 1px solid #afafaf;

            em {
              // background: #f6f8f9;
              width: 75px;
              text-indent: 5px;
              color: #666;
              border-right: 1px solid #afafaf;
            }

            span {
              width: 285px;
              padding: 2px 5px;
              line-height: 24px;
              color: #555;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
</style>